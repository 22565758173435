import React from "react";

export default function CalendarNewIcon({ className, style, size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`tbk-inline-block tbk-align-text-bottom${
        className ? " " + className : ""
      }`}
      style={style}
    >
      <path
        d="M19.1111 10.2222V8.44442C19.1111 8.20867 19.0174 7.98258 18.8507 7.81588C18.684 7.64918 18.4579 7.55553 18.2222 7.55553H17.3333V8.44442C17.3333 8.68016 17.2397 8.90625 17.073 9.07295C16.9063 9.23965 16.6802 9.3333 16.4444 9.3333C16.2087 9.3333 15.9826 9.23965 15.8159 9.07295C15.6492 8.90625 15.5555 8.68016 15.5555 8.44442V7.55553H8.44448V8.44442C8.44448 8.68016 8.35083 8.90625 8.18413 9.07295C8.01743 9.23965 7.79134 9.3333 7.55559 9.3333C7.31985 9.3333 7.09376 9.23965 6.92706 9.07295C6.76036 8.90625 6.66671 8.68016 6.66671 8.44442V7.55553H5.77783C5.54208 7.55553 5.31599 7.64918 5.14929 7.81588C4.98259 7.98258 4.88894 8.20867 4.88894 8.44442V10.2222H19.1111ZM19.1111 12H4.88894V17.3333C4.88894 17.569 4.98259 17.7951 5.14929 17.9618C5.31599 18.1285 5.54208 18.2221 5.77783 18.2221H18.2222C18.4579 18.2221 18.684 18.1285 18.8507 17.9618C19.0174 17.7951 19.1111 17.569 19.1111 17.3333V12ZM17.3333 5.77777H18.2222C18.9294 5.77777 19.6077 6.05872 20.1078 6.55881C20.6079 7.0589 20.8888 7.73718 20.8888 8.44442V17.3333C20.8888 18.0405 20.6079 18.7188 20.1078 19.2189C19.6077 19.719 18.9294 19.9999 18.2222 19.9999H5.77783C5.07059 19.9999 4.39231 19.719 3.89222 19.2189C3.39213 18.7188 3.11118 18.0405 3.11118 17.3333V8.44442C3.11118 7.73718 3.39213 7.0589 3.89222 6.55881C4.39231 6.05872 5.07059 5.77777 5.77783 5.77777H6.66671V4.88888C6.66671 4.65314 6.76036 4.42705 6.92706 4.26035C7.09376 4.09365 7.31985 4 7.55559 4C7.79134 4 8.01743 4.09365 8.18413 4.26035C8.35083 4.42705 8.44448 4.65314 8.44448 4.88888V5.77777H15.5555V4.88888C15.5555 4.65314 15.6492 4.42705 15.8159 4.26035C15.9826 4.09365 16.2087 4 16.4444 4C16.6802 4 16.9063 4.09365 17.073 4.26035C17.2397 4.42705 17.3333 4.65314 17.3333 4.88888V5.77777Z"
        fill="currentColor"
      />
    </svg>
  );
}
