import React from "react";
import { Link } from "react-router-dom";

// Components
import Button from "../../Button/Button"; // Adjust the import based on your component location

// Icons
import GiftBigIcon from "../../../icons/GiftBigIcon";

// Images
import Referral from "./Referral";

const ReferralBox = ({
  referral,
  user,
  // handleShareReferralClick,
  onCtaClick,
}) => {
  const rewords = (referral?.amount / 100).toFixed(0); //.toFixed(2);

  return (
    <div className="tbk-relative tbk-mb-2 tbk-flex tbk-flex-col tbk-justify-between tbk-rounded-2xl tbk-bg-basic-white tbk-p-2 tbk-shadow-trube md:tbk-p-3 lg:tbk-mb-0">
      <div className="tbk-absolute tbk-right-0 tbk-bottom-0 ">
        <Referral />
      </div>
      <div className="tbk-relative ">
        <div className="tbk-mb-2 tbk-flex tbk-items-center">
          <GiftBigIcon
            size={32}
            className="tbk-mr-3 tbk-shrink-0 tbk-text-coral"
          />
          <div className="tbk-text-title-bold tbk-text-grey-main">
            Your rewords: <span className="tbk-text-green">{rewords}</span>{" "}
            points
          </div>
        </div>
        <div className="tbk-text-main tbk-mb-5 tbk-pb-1 tbk-text-grey-main lg:tbk-mb-1">
          Unlock achievements, earn points, and turn your wellness into rewards!
          Every milestone gets you closer to free classes or membership perks.
        </div>
      </div>
      <Link to={"/rewards"} className="tbk-block tbk-w-full">
        <Button variant="secondary" className="tbk-w-full" onClick={onCtaClick}>
          View Details
        </Button>
      </Link>
    </div>
  );
};

export default ReferralBox;
