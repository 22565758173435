import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useHistory, useLocation } from "react-router-dom";
import Transition, {
  ENTERING,
  ENTERED,
  EXITING,
  EXITED,
} from "react-transition-group/Transition";
import Modal from "react-modal";
import Cookies from "js-cookie";
import { FormattedMessage } from "react-intl";
import { Title, Meta, Link as LinkMeta } from "react-meta-elements";

// import moment from "moment-timezone";

import { useAppState } from "../../state";
import SignUpForm from "../SignUp/SignUpForm";
import { useSignUpState } from "../../state/SignUpState";
import { usePrevState } from "../../context/PrevStateProvider";
import { useRegion } from "../../context/RegionProvider";

// Components
import Button from "../Button/Button";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TruBeDrawer from "../TruBeDrawer/TruBeDrawer";
import AvailabilityCalendar from "../TruBeDateTimePicker/AvailabilityCalendar/AvailabilityCalendar";
import LocationSelector from "../LocationSelector/LocationSelector";
import TrainerCard from "../TrainerCard/TrainerCard";
import PartnerProductBadge, {
  WorkoutIcon,
} from "../PartnerProductBadge/PartnerProductBadge";
import BookingFlowPopup from "../BookingFlow/BookingFlowPopup";
import BookingFlowPanel from "../BookingFlow/BookingFlowPanel/BookingFlowPanel";
import BookingFlowPanelHeader from "../BookingFlow/BookingFlowPanelHeader/BookingFlowPanelHeader";
import ScrollToTopOnMount from "../ScrollToTopOnMount/ScrollToTopOnMount";
import SendReviewsModal from "../SendReviewsModal/SendReviewsModal";
import TrainerCardPlaceholder from "./TrainerCardPlaceholder/TrainerCardPlaceholder";
import PhoneNumberLink from "../PhoneNumberLink/PhoneNumberLink";

// Hooks
import useTheme from "../ThemeProvider/useTheme/useTheme";
import useBodyScrollLock from "../../hooks/useBodyScrollLock/useBodyScrollLock";

// Icons
import CloseIcon from "../../icons/CloseIcon";
import MapMarkerIcon from "../../icons/MapMarkerIcon";
import CaretDownIcon from "../../icons/CaretDownIcon";
import CalendarIcon from "../../icons/CalendarTruBeIcon";
import CheckOIcon from "../../icons/CheckOIcon";
// import BlockerIcon from "../../icons/BlockerIcon";
// import CheckNiceIcon from "../../icons/CheckNiceIcon";
import PhoneIcon from "../../icons/PhoneIcon";
import EnvelopeIcon from "../../icons/EnvelopeIcon";

// Utils
import {
  clientWidth,
  getLastUsedLocation,
  makeUrlString,
  toQueryString,
} from "../../utils";
import { getMomentWithTz } from "../../utils/date";
import {
  modalStyles,
  PARTNERS_PAGE_SIZE,
  // PAGE_SIZE,
} from "../../constants";

// Images
import Partners from "./Partners";

import { areas } from "../../california-zip-codes";

// const SCROLL_UP_TRESHOLD = 150;
const SCROLL_DOWN_TRESHOLD = 150;
const MAX_PRODUCTS_TO_SHOW = 8;
const CONTINUE_BOOKING_FLOW_PARAM_NAME = "continue_with_partner";
const INFO_MODAL_HIDE_TIMEOUT = 5000;

let placeholders = [];

for (var i = 0; i < PARTNERS_PAGE_SIZE; i++) {
  placeholders.push(i);
}

const transitionClasses = {
  [ENTERED]: "booking-flow-popup-transition-entered",
  [ENTERING]: "booking-flow-popup-transition-entering",
  [EXITING]: "booking-flow-popup-transition-exiting",
  [EXITED]: "booking-flow-popup-transition-exited",
};

const workoutsHash = {
  "personal-training": "Personal Training",
  boxing: "Boxing",
  kickboxing: "Kickboxing",
  "ballet-fit": "Ballet",
  running: "Running",
  "prepost-natal": "Pre/Post Natal",
  yoga: "Yoga",
  pilates: "Pilates",
  stretching: "Stretching",
  meditation: "Meditation",
  "sound-baths": "Sound Baths",
  "sound-healing": "Sound Healing",
  lifestyle: "Lifestyle Coaching",
  "stress-management": "Stress Management",
  motivation: "Motivational Coaching",
  "cymbiotika-certified": "Cymbiotika Certified",
  "spiritual-gangster": "Spiritual Gangster Certified", // As a fallback
  "spiritual-gangster-certified": "Spiritual Gangster Certified",
};

const TabButton = ({ className, active, categoryName, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(categoryName);
  }, [categoryName, onClick]);

  return (
    <button
      className={`${
        className ? className + " " : ""
      }tbk-text-cta tbk-mt-1 tbk-mb-1 tbk-pl-2 tbk-pr-2 tbk-uppercase hover:tbk-text-coral hover:tbk-underline ${
        active ? "tbk-text-coral tbk-underline" : "tbk-text-blue-dark"
      }`}
      onClick={handleClick}
    >
      {categoryName}
    </button>
  );
};

const DisciplineItem = ({ className, selected, product, onClick }) => {
  const handleClick = useCallback(() => {
    onClick && onClick(product);
  }, [product, onClick]);

  return (
    <button
      className={`tbk-flex tbk-items-center${className ? " " + className : ""}`}
      onClick={handleClick}
    >
      <div
        className={`tbk-mr-1 tbk-flex tbk-h-4 tbk-w-4 tbk-items-center tbk-justify-center tbk-rounded-lg ${
          selected
            ? "tbk-bg-coral tbk-text-basic-white"
            : "tbk-bg-grey-light tbk-text-blue-dark"
        }`}
      >
        <WorkoutIcon label={product.name} />
      </div>
      <div
        className={`tbk-text-h3-subtitle tbk-grow ${
          selected ? "tbk-text-coral" : "tbk-text-blue-dark"
        }`}
      >
        {product.name}
      </div>
    </button>
  );
};

export default function OurTrainersPage() {
  const history = useHistory();
  const location = useLocation();
  const { theme } = useTheme();
  const { region } = useRegion();

  const {
    user,
    hasTriedToAutoLogin,
    products,
    loadProducts,
    partners,
    gyms,
    availablePartners,
    partnerGyms,
    promoCode: userPromoCode,
    setPromoCode,
    referralCode,
    setReferralCode,
    applyReferral,
    loadAvailablePartners,
    loadPartnersInLocation: loadPartners,
    loadPartnerProfile,
    loadPartnerAvailability,
    loadGyms,
    loadPartnerGyms,
    clearPartnerSummaryReceive,
    partnerLoaded,
    partnerSummary,
    partnerAvailability,
    partnerAvailabilityLoaded,
    productsLoaded,
    availablePartnersLoaded,
    hasMorePartners,
    availablePartnersPageLoaded,
    isFetching,
    isProductsFetching,
    isAavailablePartnersFetching,
    // clearPartnerSummaryReceive,
    clearPartnerAvailabilityLoaded,
    clearAvailablePartnersLoaded,
  } = useAppState();

  const { prevState } = usePrevState();

  const {
    userPhoneCode,
    userPhoneNumber,
    // userEmail,
    // userFirstName,
    // userFamilyName,
    accessToken,
    identityToken,
    setAuthState,
  } = useSignUpState();

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isLocationSelectorModalOpen, setIsLocationSelectorModalOpen] =
    useState(false);
  const [availabilityCalendarMin] = useState(
    getMomentWithTz(new Date(), region)
      .startOf("day")
      .add(330 /*05:30*/, "minute")
  );
  const [availabilityCalendarMax] = useState(
    getMomentWithTz(new Date(), region)
      .startOf("day")
      .add(23 /*23:30??*/, "hour")
      .add(2, "month")
  );
  const [availabilityCalendarDay] = useState(
    getMomentWithTz(new Date(), region)
  );
  const [_startDate, setStartDate] = useState(undefined);
  const [_endDate, setEndDate] = useState();
  const [isTimeChanged, setIsTimeChanged] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [_focusedInput, setFocusedInput] = useState();
  const [_bookingTime, setBookingTime] = useState(
    /*dateTime ? new Date(dateTime).getTime() : */ undefined
  );
  const [_directBookingTime, setDirectBookingTime] = useState(
    /*dateTime ? new Date(dateTime).getTime() : */ undefined
  );

  // eslint-disable-next-line no-unused-vars
  const [timeChanged, setTimeChanged] = useState(false);
  const [showAvailabilityCalendar, setShowAvailabilityCalendar] =
    useState(false);
  // TODO move to BookingFilters component
  // eslint-disable-next-line no-unused-vars
  const [_lastKnownPageYOffset, setLastKnownPageYOffset] = useState();
  // eslint-disable-next-line no-unused-vars
  const [storedPageYOffset, setStoredPageYOffset] = useState();
  const [showAll, setShowAll] = useState(false);
  const [activeProductCategory, setActiveProductCategory] = useState("All");
  const [selectedProduct, setSelectedProduct] = useState();
  const [filters, setFilters] = useState([
    null /* selected location */,
    undefined /* booking time*/,
    undefined /* selected product */,
  ]);
  const [_products, setProducts] = useState([]);
  const [isDisciplinesDrawerOpen, setIsDisciplinesDrawerOpen] = useState(false);
  const [isBookingFlowPopupOpen, setIsBookingFlowPopupOpen] = useState(false);
  const [isBookingFlowPanelOpen, setIsBookingFlowPanelOpen] = useState(false);
  const [isBookingFlowPanelFullyOpen, setIsBookingFlowPanelFullyOpen] =
    useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [infoModalTitle, setInfoModalTitle] = useState("");
  const [infoModalMsg, setInfoModalMsg] = useState("");
  const [bookedPartner, setBookedPartner] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [sessionType, setSessionType] = useState("ONLINE");
  const [bookingStep, setBookingStep] = useState("CHOOSE_DAY_TIME");
  const [directWorkout, setDirectWorkout] = useState();
  const [showSpecifyLocationInfo, setShowSpecifyLocationInfo] = useState(false);
  const [showAllGyms, setShowAllGyms] = useState(false); // TODO make more robust
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [isBookingFlow, setIsBookingFlow] = useState(null);
  const [preserveBookedPartner, setPreserveBookedPartner] = useState(false);
  const [filtersReady, setFiltersReady] = useState(false);
  const [allowDragging, setAllowDragging] = useState(false);

  const lastKnownPageYOffsetRef = useRef();

  // TODO make wrapper component that would apply filters withFilters
  const lastUsedLocationRef = useRef(); // For filters
  const selectedProductRef = useRef(); // For filters
  const startDateRef = useRef(null /*startDate*/); // For filters
  const dateWithTimeRef = useRef(/*dateTime*/); // For filters

  const showAvailabilityCalendarRef = useRef(false);
  const getAvailablePartnersCancelablePromiseRef = useRef();
  const getPartnersCancelablePromiseRef = useRef();
  const panelRef = useRef(null);
  const panelContainerRef = useRef(null);

  const bookingFlowPopupRef = useRef(null);
  const bookingFlowPanelRef = useRef(null);
  const locationSelectorResolveRef = useRef(null);
  const infoModalResolveRef = useRef(null);
  const loadPartnerProfilePromiseRef = useRef(null);
  const isLocationSelectorModalOpenRef = useRef(false);
  const loadOnMountRef = useRef(true);
  const filtersRef = useRef(filters);
  const preservePartnerRef = useRef();

  const closeBookingFlowPanelResolveRef = useRef(null);

  const updateBookingTime = useCallback((time, updateFilters = true) => {
    setBookingTime(time);

    if (updateFilters) {
      let newFilters = [...filtersRef.current];

      // Second filter is booking time
      newFilters[1] = time;

      setFilters(newFilters);
      filtersRef.current = newFilters;
      loadOnMountRef.current = true; // Allow filters to work, we're already made a check to not load on mount
    } else {
      // Second filter is booking time
      filtersRef.current[1] = time;
    }
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    const appleLogin = query.get("apple_login");

    if (
      (prevState &&
        prevState.some(
          (v) =>
            v.includes("/partners/") ||
            v.includes("become-a-member") ||
            v.includes("/checkout") ||
            v.includes("/login")
        )) ||
      appleLogin
    ) {
      if (appleLogin) {
        query.delete("apple_login");

        history.replace({
          pathname: location.pathname,
          search: query.toString(),
        });
      }

      let startDate = window.sessionStorage.getItem("filters/date");
      let dateTime = window.sessionStorage.getItem("filters/dateWithTime");

      setStartDate(
        dateTime
          ? getMomentWithTz(new Date(dateTime), region)
          : startDate
          ? getMomentWithTz(new Date(startDate), region)
          : undefined
      );

      updateBookingTime(
        dateTime
          ? new Date(dateTime).getTime()
          : startDate
          ? new Date(startDate).getTime()
          : undefined,
        false
      );
      setDirectBookingTime(
        dateTime
          ? new Date(dateTime).getTime()
          : startDate
          ? new Date(startDate).getTime()
          : undefined
      );

      startDateRef.current = startDate;
      dateWithTimeRef.current = dateTime;

      setIsTimeChanged(Boolean(dateTime));
    } else {
      window.sessionStorage.removeItem("filters/date");
      window.sessionStorage.removeItem("filters/dateWithTime");
    }
  }, [prevState]);

  const locationSelectorModalCustomStyle =
    clientWidth() < theme.responsive.breakpoints.sm
      ? Object.assign(
          {},
          { ...modalStyles.mobileDefault },
          {
            content: {
              ...modalStyles.mobileDefault.content,
              display: "flex",
              height: "auto",
              minHeight: showAllGyms ? null : "764px", // "642px",
              padding: "16px",
              overflow: showAllGyms ? "hidden" : "visible",
            },
          }
        )
      : clientWidth() < theme.responsive.breakpoints.md
      ? Object.assign(
          {},
          { ...modalStyles.reviewsModal },
          {
            content: {
              ...modalStyles.reviewsModal.content,
              width: "80%",
              height: "619px", // "auto",
              // minHeight: "619px",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
            },
          }
        )
      : Object.assign(
          {},
          { ...modalStyles.reviewsModal },
          {
            content: {
              ...modalStyles.reviewsModal.content,
              width: "800px",
              height: "619px", // "auto",
              // minHeight: "619px",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
            },
          }
        ); // <= 576?

  const openLocationSelectorModal = useCallback(() => {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      locationSelectorResolveRef.current = resolve;

      setIsLocationSelectorModalOpen(true);
      isLocationSelectorModalOpenRef.current = true;

      // Analytics.trackEvent(TRAINERS_LIST_PAGE_CATEGORY, 'Open', EVENT_LOCATION_PICKER_OPEN);
    });
  }, []);

  // `closeLocationSelectorModal` callback should go before `useBodyScrollLock` hook,
  // because of `setShowAllGyms(false)` call, otherwise closing the modal will block the scroll
  // and `useBodyScrollLock` won't have a chance to "unblock" it

  /*
   * After closing the Location Selector Modal (promise resolved) the booking flow continues.
   * @param bail -- whether to skip the booking flow
   */
  const closeLocationSelectorModal = useCallback(
    (bail = false) => {
      if (
        bookedPartner &&
        partnerAvailable(bookedPartner.id, availablePartners) &&
        !bail
      ) {
        locationSelectorResolveRef.current(selectedLocation);
        locationSelectorResolveRef.current = null;
        setPreserveBookedPartner(false);
        setBookedPartner(null);
        clearPartnerSummaryReceive();
      } else {
        setIsLocationSelectorModalOpen(false);
        isLocationSelectorModalOpenRef.current = false;
        setShowAllGyms(false);

        return Promise.reject();
      }

      setIsLocationSelectorModalOpen(false);
      isLocationSelectorModalOpenRef.current = false;
      setShowAllGyms(false);
    },
    [selectedLocation, bookedPartner, availablePartners]
  );

  const { /*bottomBarHeight, */ refCallback, targetRef } =
    useBodyScrollLock(showAllGyms);

  const handleScrollUp = useCallback(() => {}, []);

  const handleScrollDown = useCallback(() => {
    const windowPageYOffset = window.pageYOffset;

    if (
      windowPageYOffset > SCROLL_DOWN_TRESHOLD &&
      showAvailabilityCalendarRef.current
    ) {
      setShowAvailabilityCalendar(false);
      showAvailabilityCalendarRef.current = false;
    }

    setStoredPageYOffset(windowPageYOffset);
  }, []);

  const handleScroll = useCallback(
    () => {
      const lastKnownPageYOffset = lastKnownPageYOffsetRef.current;
      const windowPageYOffset = window.pageYOffset;

      // Fixes iOS bug when Header cannot "dock"
      if (lastKnownPageYOffset === windowPageYOffset) {
        return;
      }

      if (lastKnownPageYOffset > windowPageYOffset) {
        handleScrollUp();
      } else {
        handleScrollDown();
      }

      setLastKnownPageYOffset(windowPageYOffset);
      lastKnownPageYOffsetRef.current = windowPageYOffset;
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handlePanelScroll = useCallback(
    () => {
      const scrollTop = panelContainerRef.current.scrollTop;

      if (scrollTop === 0) {
        setAllowDragging(true);
      } else {
        setAllowDragging(false);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getAvailablePartners = useCallback(() => {
    var params = {};
    const [selectedLocation, bookingTime, selectedProduct] = filtersRef.current;

    // Location is mandatory
    // Not now
    // if (!selectedLocation) {
    //   return;
    // }

    if (isTimeChanged && bookingTime) {
      params.dateWithTime = new Date(Number(bookingTime)).toISOString();
    } else if (bookingTime) {
      params.date = new Date(Number(bookingTime)).toISOString();
    }

    if (selectedProduct) {
      params.productId = selectedProduct.id;
    }

    if (selectedLocation) {
      if (selectedLocation.postalCode) {
        params.locationCode = `${selectedLocation.postalCode.replace(
          / .*/,
          ""
        )}`;
      } else if (selectedLocation.placeId) {
        params.placeValue = selectedLocation.value.place;
      } else if (selectedLocation.value && selectedLocation.value.place_id) {
        params.locationCode = areas
          .filter((val) => val.placeId === selectedLocation.value.place_id)
          .map((val) => `${val.zipCode.replace(/ .*/, "")}`);
      }
    }

    return loadAvailablePartners(
      params,
      user ? user.region : region /*process.env.REACT_APP_REGION*/
    );
  }, [user, hasTriedToAutoLogin, isTimeChanged, loadAvailablePartners]);

  const loadMoreAvailablePartners = useCallback(
    (page = 0) => {
      const params = {
        size: PARTNERS_PAGE_SIZE,
        page,
      };

      const [selectedLocation, bookingTime, selectedProduct] =
        filtersRef.current;

      if (isTimeChanged && bookingTime) {
        params.dateWithTime = new Date(Number(bookingTime)).toISOString();
      } else if (bookingTime) {
        params.date = new Date(Number(bookingTime)).toISOString();
      }

      if (selectedProduct) {
        params.productId = selectedProduct.id;
      }

      if (selectedLocation) {
        if (selectedLocation.postalCode) {
          params.locationCode = `${selectedLocation.postalCode.replace(
            / .*/,
            ""
          )}`;
        } else if (selectedLocation.placeId) {
          params.placeValue = selectedLocation.value.place;
        } else if (selectedLocation.value && selectedLocation.value.place_id) {
          params.locationCode = areas
            .filter((val) => val.placeId === selectedLocation.value.place_id)
            .map((val) => `${val.zipCode.replace(/ .*/, "")}`);
        }
      }

      loadAvailablePartners(
        params,
        user ? user.region : region /*process.env.REACT_APP_REGION*/
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [isFetching, user, isTimeChanged, loadAvailablePartners]
  );

  const getPartners = useCallback(() => {
    var params = {};

    if (!selectedLocation) {
      return;
    }

    if (selectedLocation.postalCode) {
      params.locationCode = `${selectedLocation.postalCode.replace(/ .*/, "")}`;
    } else if (selectedLocation.placeId) {
      params.placeValue = selectedLocation.value.place;
    } else if (selectedLocation.value && selectedLocation.value.place_id) {
      params.locationCode = areas
        .filter((val) => val.placeId === selectedLocation.value.place_id)
        .map((val) => `${val.zipCode.replace(/ .*/, "")}`);
    }

    return loadPartners(
      params,
      user ? user.region : region /*process.env.REACT_APP_REGION*/
    );
  }, [user, selectedLocation, loadPartners]);

  const getAvailablePartnersRef = useRef(getAvailablePartners);
  const getPartnersRef = useRef(getPartners);

  const openInfoModal = useCallback(
    (msg, title = "Sorry", autoHide = false) => {
      return new Promise((resolve, reject) => {
        infoModalResolveRef.current = resolve;

        setIsInfoModalOpen(true);

        // if (title === "Sorry") {
        //   setInfoModalStatus(":(");
        // } else {
        //   setInfoModalStatus("Success");
        // }
        setInfoModalTitle(title);
        setInfoModalMsg(msg);

        if (autoHide) {
          setTimeout(() => resolve(), INFO_MODAL_HIDE_TIMEOUT);
        }
      });
    },
    []
  );

  const closeInfoModal = useCallback(() => {
    infoModalResolveRef.current();
    infoModalResolveRef.current = null;

    setIsInfoModalOpen(false);
  }, []);

  const closeSignUpModal = () => {
    setIsSignUpModalOpen(false);
  };

  const setPromoCodeCookie = React.useCallback(() => {
    const cookieOptions = { path: "/" };

    Cookies.set("promo-code", "shown", cookieOptions);
  }, []);

  const updateSelectedProduct = useCallback((product, updateFilters = true) => {
    setSelectedProduct(product);
    selectedProductRef.current = product;

    if (updateFilters) {
      let newFilters = [...filtersRef.current];

      // Third filter is product
      newFilters[2] = product;

      setFilters(newFilters);
      filtersRef.current = newFilters;
      loadOnMountRef.current = true; // Allow filters to work, we're already made a check to not load on mount
    } else {
      // Third filter is product
      filtersRef.current[2] = product;
    }
  }, []);

  const updateSelectedLocation = useCallback(
    (location, updateRef = false, updateFilters = true) => {
      setSelectedLocation(location);

      if (updateRef) {
        lastUsedLocationRef.current = location;
      }

      if (updateFilters) {
        let newFilters = [...filtersRef.current];

        // First filter is the location
        newFilters[0] = location;

        setFilters(newFilters);
        filtersRef.current = newFilters;
        loadOnMountRef.current = true; // Allow filters to work, we're already made a check to not load on mount
      } else {
        // First filter is the location
        filtersRef.current[0] = location;
      }
    },
    []
  );

  // useEffect(() => {
  //   if ("shown" !== Cookies.get("promo-code") && user && userPromoCode) {
  //     openInfoModal(
  //       "Your discount will be automatically applied to your first booking.",
  //       ""
  //     ).then(() => {
  //       setIsInfoModalOpen(false);

  //       setPromoCodeCookie();
  //     });
  //   }
  // }, [user, userPromoCode]);

  useEffect(() => {
    if (!hasTriedToAutoLogin) {
      return;
    }

    if (!productsLoaded) {
      loadProducts(
        user ? user.region : region /*process.env.REACT_APP_REGION*/
      );
      loadGyms({
        region: user ? user.region : region /*process.env.REACT_APP_REGION*/,
      }); // :>
    }
  }, [user, hasTriedToAutoLogin, productsLoaded]);

  useEffect(
    () => {
      window.addEventListener("scroll", handleScroll);

      // if (!productsLoaded) {
      //   loadProducts();
      // }

      if (panelContainerRef.current) {
        panelContainerRef.current.addEventListener("scroll", handlePanelScroll);
      }

      // TODO make more robust
      let lastUsedLocation = getLastUsedLocation(user); //  = JSON.parse(localStorage.getItem(storageKey));

      if (lastUsedLocation) {
        // When we get location from localstorage we shouldn't trigger the loadAvailablePartner to trigger, so `false`
        updateSelectedLocation(lastUsedLocation, true, false);
      } else if (Cookies.get("location_selector") !== "shown") {
        const cookieOptions = { path: "/" };

        Cookies.set("location_selector", "shown", cookieOptions);

        openLocationSelectorModal();
      }

      const referralCode = Cookies.get("referral");

      if (referralCode) {
        setReferralCode(referralCode);
      }

      // getAvailablePartnersCancelablePromiseRef.current = getAvailablePartners();
      //
      // getAvailablePartnersCancelablePromiseRef.current.promise
      //   .catch(res => !res.isCanceled && console.error('Could not get available partners'));

      return () => {
        window.removeEventListener("scroll", handleScroll);

        if (panelContainerRef.current) {
          panelContainerRef.current.removeEventListener(
            "scroll",
            handlePanelScroll
          );
        }

        clearPartnerSummaryReceive();
      };
    },
    [productsLoaded] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // Why????
  // useEffect(() => {
  //   loadGyms({ region: user ? user.region : "US" }); // :>
  // }, [user]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const promoCode = query.get("promocode");

    if (promoCode) {
      setPromoCode(promoCode);
      // Referral code is valid
      openInfoModal(
        `Discount applied at checkout`,
        "Enjoy 30% Off Membership",
        true
      ).then(() => {
        setIsInfoModalOpen(false);
      });
    }

    if (user && referralCode) {
      applyReferral(user.id, referralCode)
        // .then((res) => {
        //   // Referral code is valid
        //   openInfoModal(
        //     `You received ${res.data.symbol}${res.data.receiverReward} referral credits.`,
        //     "Referral Bonus Applied"
        //   );
        // })
        // .catch((res) => {
        //   openInfoModal(res.message, "Link Error");
        // })
        .finally(() => {
          // Regardless the referral code is valid or not we don't need it anymore
          // (so it won't be applied on re-login for example)
          setReferralCode("");

          Cookies.remove("referral");
        });
    }
  }, [location, user, referralCode]);

  useEffect(() => {
    if (productsLoaded) {
      console.log("Products loaded.");

      const productsToSet = getProductsForCategory(
        products,
        activeProductCategory
      );

      setProducts(productsToSet);
    }
  }, [products, activeProductCategory, productsLoaded]);

  useEffect(() => {}, [products, activeProductCategory]);

  useEffect(() => {
    getAvailablePartnersRef.current = getAvailablePartners;
  }, [getAvailablePartners]);

  useEffect(() => {
    getPartnersRef.current = getPartners;
  }, [getPartners]);

  React.useLayoutEffect(() => {
    console.log("prevState", prevState);
    if (
      prevState &&
      prevState.some(
        (v) =>
          !v.includes("/partners/cymbiotika-certified") &&
          (v.includes("/partners/") || v.includes("/checkout"))
      ) &&
      availablePartnersLoaded
    ) {
      loadOnMountRef.current = false;
      // Interferes with when product is in the url. We set in the Products useEffect
      //setFiltersReady(true);
    }
  }, [prevState, availablePartnersLoaded]);

  // Should go after setting getAvailablePartnersRef with updated callback
  useEffect(() => {
    if (!filtersReady || !loadOnMountRef.current || !hasTriedToAutoLogin) {
      return;
    }

    getAvailablePartnersCancelablePromiseRef.current &&
      getAvailablePartnersCancelablePromiseRef.current.cancel();
    getAvailablePartnersCancelablePromiseRef.current =
      getAvailablePartnersRef.current();

    return () => {
      getAvailablePartnersCancelablePromiseRef.current &&
        getAvailablePartnersCancelablePromiseRef.current.cancel();
    };
  }, [hasTriedToAutoLogin, filtersReady, filters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (products && products.length) {
      const productName = location.pathname.replace("/partners/", "");
      let matchedProduct = getProductByName(
        products,
        workoutsHash[productName]
      );

      if (matchedProduct) {
        updateSelectedProduct(matchedProduct, false); // Specifically do not update the filters

        if (!isProductVisible(products, matchedProduct.name)) {
          setShowAll(true);
        }
      } else {
        const product = getProductWithCategory(products, productName);

        if (product) {
          setActiveProductCategory(product.category);
        }
      }

      setFiltersReady(true);
    }
  }, [location, products]);

  useEffect(() => {
    // So we do it only once, for the first time. Coaches browsing fix
    if (isProductsFetching && productsLoaded) {
      loadOnMountRef.current = true;
    }
  }, [productsLoaded, isProductsFetching]);

  useEffect(() => {
    // So the old partners won't be used when we change filters
    if (loadOnMountRef.current) {
      clearAvailablePartnersLoaded();
    }
  }, [filters]);

  // Should go after setting getPartnersRef with updated callback
  useEffect(() => {
    if (!isLocationSelectorModalOpen) {
      return;
    }

    // Get partners for Location Selector modal
    getPartnersCancelablePromiseRef.current &&
      getPartnersCancelablePromiseRef.current.cancel();
    getPartnersCancelablePromiseRef.current = getPartnersRef.current();

    return () => {
      getPartnersCancelablePromiseRef.current &&
        getPartnersCancelablePromiseRef.current.cancel();
    };
  }, [selectedLocation, isLocationSelectorModalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (bookedPartner) {
      if (clientWidth() < theme.responsive.breakpoints.lg) {
        setIsBookingFlowPanelOpen(true);
      } else {
        setIsBookingFlowPopupOpen(true);
      }

      loadPartnerAvailability(bookedPartner.id);
      loadPartnerGyms(bookedPartner.id);
    }
  }, [bookedPartner, theme.responsive.breakpoints.lg]);

  const handleLocationSelect = useCallback((value) => {
    updateSelectedLocation(value);

    if (value && (value.postalCode || value.placeId)) {
      setShowSpecifyLocationInfo(true);
    } else {
      setShowSpecifyLocationInfo(false);
    }

    if (value === null) {
      setShowSpecifyLocationInfo(false);
      lastUsedLocationRef.current = null;
    }
  }, []);

  const handleLocationClick = useCallback(() => {
    openLocationSelectorModal();
  }, [openLocationSelectorModal]);

  /**
   * @param dateObj
   * @param timeChanged Boolean - has time been selected
   */
  const handleDatesChange = useCallback(
    ({ startDate, endDate, focusedInput }, timeChanged) => {
      if (!isTimeChanged) {
        // changeFiltersBookingDateFilter({ bookingDate: startDate.valueOf() });
      }

      var dateChanged = /*startDateRef.current !== null || */ !startDate.isSame(
        startDateRef.current,
        "day"
      );

      if (dateChanged) {
      } else {
        startDate = null; // startDate.startOf('day');

        dateWithTimeRef.current = null;
      }

      updateBookingTime(startDate ? startDate.valueOf() : undefined);

      setDirectBookingTime(startDate ? startDate.valueOf() : undefined);
      setStartDate(startDate);
      setEndDate(endDate);
      setFocusedInput(focusedInput);

      startDateRef.current = startDate;

      if (!timeChanged && !dateChanged) {
        window.sessionStorage.removeItem("filters/date");
        window.sessionStorage.removeItem("filters/dateWithTime");
      }

      if (dateChanged) {
        window.sessionStorage.setItem("filters/date", startDate.toISOString());
      }
    },
    [isTimeChanged, location]
  );

  const handleTimeChange = useCallback(
    ({ dateTime, dateStr }, timeChanged = true) => {
      var newStartDate = getMomentWithTz(dateTime, region);

      if (!timeChanged) {
        setIsTimeChanged(false);

        dateWithTimeRef.current = null;

        dateTime.setHours(0, 0, 0, 0);

        newStartDate = newStartDate.startOf("day");

        window.sessionStorage.removeItem("filters/dateWithTime");
      } else {
        setIsTimeChanged(timeChanged);

        dateWithTimeRef.current = newStartDate;

        window.sessionStorage.setItem(
          "filters/dateWithTime",
          newStartDate.toISOString()
        );
      }
      updateBookingTime(newStartDate ? newStartDate.valueOf() : undefined);
      setDirectBookingTime(newStartDate ? newStartDate.valueOf() : undefined);

      setStartDate(newStartDate);
    },
    [location]
  );

  const handleDisciplineFilterClick = useCallback(() => {
    setIsDisciplinesDrawerOpen(true);
  }, []);

  const handleChooseTimeDateClick = useCallback(() => {
    setShowAvailabilityCalendar(true);
    showAvailabilityCalendarRef.current = true;
  }, []);

  const handleShowAllClick = useCallback(() => {
    setShowAll(true);
  }, []);

  const handleCategoryChange = useCallback(
    (category) => {
      if (category !== activeProductCategory) {
        setActiveProductCategory(category);

        const newProducts = getProductsForCategory(products, category);

        setProducts(newProducts);
      }
    },
    [products, activeProductCategory]
  );

  const handleProductSelect = useCallback(
    (prod) => {
      if (prod && (!selectedProduct || selectedProduct.id !== prod.id)) {
        updateSelectedProduct(prod);
        setDirectWorkout(prod);

        history.replace({
          pathname: `/partners/${makeUrlString(prod.name)}`,
          search: window.location.search,
        });

        // Only for production
        if (typeof klaviyo !== "undefined") {
          // eslint-disable-next-line no-undef
          klaviyo.track("Viewed Category", {
            CategoryName: prod.name,
            CategoryID: prod.id,
            // "ImageURL": "http://www.example.com/path/to/category/hero/image.png",
            URL: `https://${window.location.hostname}/partners/${makeUrlString(
              prod.name
            )}`,
          });
        }
      } else if (selectedProduct.id === prod.id) {
        updateSelectedProduct(null);
        setDirectWorkout(null);

        history.replace({
          pathname: "/partners",
          search: window.location.search,
        });
      }

      if (isDisciplinesDrawerOpen) {
        setIsDisciplinesDrawerOpen(false);
      }
    },
    [selectedProduct, isDisciplinesDrawerOpen]
  );

  const handleBottomReached = useCallback(() => {
    if (isDisciplinesDrawerOpen) {
      setIsDisciplinesDrawerOpen(false);
    } else if (isBookingFlowPanelOpen) {
      setIsBookingFlowPanelOpen(false);
    }
  }, [isDisciplinesDrawerOpen, isBookingFlowPanelOpen]);

  const handleFullyOpen = useCallback(() => {
    setIsBookingFlowPanelFullyOpen(true);
  }, []);

  const closeDisciplinesDrawer = () => {
    setIsDisciplinesDrawerOpen(false);
  };

  /*
   *  PartnerSummary has been fetched in the DirectBookingForm.
   *
   *  We don't get the location with the parameters, as it's expected to be set beforehand
   *  in DirectBookingForm or BookingForm
   *
   *  params = {
   *    productId: String,
   *    startTime: Number,
   *    availablePartners: [partner.id]
   *  }
   *
   */
  const sendBooking = useCallback(() => {
    return Promise.resolve()
      .then(() => {
        if (
          !selectedLocation ||
          !(selectedLocation.postalCode || selectedLocation.placeId)
        ) {
          selectedLocation && setShowSpecifyLocationInfo(true);

          // For mobiles only? We are using closeBookingFlowPanel() here in conjunctions with
          // setPreserveBookedPartner to prevent clearing on Drawer closing
          setPreserveBookedPartner(true);
          closeBookingFlowPanel(true);

          return openLocationSelectorModal();
        }

        return Promise.resolve(selectedLocation);
      })
      .then((selectedLocation) => {
        var urlParams = {
          postal_code:
            selectedLocation.postalCode &&
            selectedLocation.postalCode.replace(/ .*/, ""),
          location: `${selectedLocation.lat},${selectedLocation.lng}`,
          address: selectedLocation.label,
          partner: bookedPartner.id,
          product: directWorkout.id,
          start_time: _directBookingTime,
        };

        if (selectedLocation.placeId) {
          delete urlParams.postal_code;

          urlParams.place = selectedLocation.value.place;
          urlParams.place_name = selectedLocation.value.name;
        }

        // closeBookingFlowPanelAsPromise();

        // it might be closed in location check step..
        if (preservePartnerRef.current || isBookingFlowPanelOpen) {
          new Promise((resolve) => {
            closeBookingFlowPanelResolveRef.current = resolve;
            closeBookingFlowPanel(undefined, true); // if it's already closed, then just resolve..
          }).then(() => {
            setTimeout(() => {
              history.push(`/checkout?${toQueryString(urlParams)}`);
            }, 500);
          });
        } else {
          history.push(`/checkout?${toQueryString(urlParams)}`);
        }
      });
  }, [
    isBookingFlowPanelOpen,
    selectedLocation,
    directWorkout,
    _directBookingTime,
    bookedPartner,
    openLocationSelectorModal,
    history,
  ]);

  const handleDirectWorkoutChange = useCallback(
    (product) => {
      if (product && (!directWorkout || directWorkout.id !== product.id)) {
        setDirectWorkout(product);
      }
    },
    [directWorkout]
  );

  const handleDirectBookingTimeChange = useCallback(
    (dateTime, isTimeSelected) => {
      // Analytics.trackEvent(TRAINERS_LIST_PAGE_CATEGORY, '', EVENT_DATETIME_CHANGE);

      setDirectBookingTime(dateTime ? dateTime.getTime() : undefined);
    },
    []
  );

  const closeBookingFlowPopup = useCallback(() => {
    setIsBookingFlowPopupOpen(false);
    setBookedPartner(null);
    clearPartnerSummaryReceive(); // To prevent showing previously loaded partner in BookingFlowPopup
  }, []); // eslint-disable-line

  const closeBookingFlowPanel = useCallback(
    (_preserveBookedPartner, fromCb) => {
      if (fromCb) {
        // FIXME when called from TruBeDrawer close func. very tricky, should be removed
        // resolving the promise. should we check if it's exists? prob. yes.
        closeBookingFlowPanelResolveRef.current &&
          closeBookingFlowPanelResolveRef.current();
        preservePartnerRef.current = null;
      } else {
        preservePartnerRef.current = _preserveBookedPartner; // For scroll restoration
      }

      console.log(
        "_preserveBookedPartner, fromCb",
        _preserveBookedPartner,
        fromCb
      );
      // Calling twice bc it initiates the closing and the second time as onClose callback.
      setIsBookingFlowPanelOpen(false);
      setIsBookingFlowPanelFullyOpen(false);

      if (!(preserveBookedPartner || _preserveBookedPartner)) {
        // TODO Probably it'll be a good idea to do this separately in Drawer onClose
        setBookedPartner(null);
        clearPartnerSummaryReceive(); // To prevent showing previously loaded partner in BookingFlowPopup
      }
    },
    [preserveBookedPartner]
  ); // eslint-disable-line

  const handleBookWithMeRequest = useCallback(
    (e) => {
      sendBooking();
    },
    [sendBooking]
  );

  const handleTimeSlotsClick = useCallback(
    (e, partner) => {
      e.preventDefault();
      e.stopPropagation();

      if (user) {
        setBookedPartner(partner);
        setBookingStep("CHOOSE_DAY_TIME");
        loadPartnerProfile(partner.id); // Move to the hook? no!
      } else {
        setIsBookingFlow(partner.id);
        setIsSignUpModalOpen(true);
      }

      // Analytics.trackEvent(TRAINERS_LIST_PAGE_CATEGORY, 'Open', EVENT_TIME_SLOTS_POPUP_OPEN);
    },
    [user]
  ); // eslint-disable-line

  const continueBookingFlow = useCallback(() => {
    const query = new URLSearchParams(location.search);

    const bookingFlowPartner = query.get(CONTINUE_BOOKING_FLOW_PARAM_NAME);

    if (!user || partnerLoaded) {
      return;
    }

    if (bookingFlowPartner) {
      loadPartnerProfilePromiseRef.current =
        loadPartnerProfile(bookingFlowPartner);
      loadPartnerProfilePromiseRef.current.promise.then((res) => {
        setBookedPartner(res);
      });

      query.delete(CONTINUE_BOOKING_FLOW_PARAM_NAME);

      history.replace({
        search: query.toString(),
      });
    }
  }, [location, user, partnerLoaded]);

  useEffect(() => {
    continueBookingFlow();

    return () => {
      // loadPartnerProfilePromiseRef.current &&
      //   loadPartnerProfilePromiseRef.current.cancel(); //bookingFlowPartner
    };
  }, [continueBookingFlow]);

  const handleBookingFlowPopupCtaClick = useCallback(() => {
    if (bookingStep === "CHOOSE_DAY_TIME") {
      setBookingStep("CHOOSE_WORKOUT_TYPE");
    } else {
      handleBookWithMeRequest();
    }
  }, [bookingStep, handleBookWithMeRequest]);

  const goToTrainerProfile = useCallback(
    (evt, trainer) => {
      history.push(
        `/partners/${trainer.id}${
          _directBookingTime ? `?start_time=${_directBookingTime}` : ""
        }`
      );
    },
    [history, _directBookingTime]
  );

  const isOnline = false;

  return (
    <div className="tbk-h-full lg:tbk-flex lg:tbk-flex-col">
      <ScrollToTopOnMount />
      <Title>TruBe: Connect with Top Fitness & Wellbeing Trainers</Title>
      <Meta
        name="description"
        content="TruBe connects you with the best fitness & wellbeing trainers, specialising in one-to-one sessions for Pilates, Yoga, Personal Training, HIIT, Running, and more"
      />
      <LinkMeta rel="canonical" href="https://trubeapp.com/partners" />
      <Header showRegionSelect={false} showLinks={true} behaviour="none" />

      <div className="tbk-bg-grey-light">
        <div
          className={`tbk-container tbk-mx-auto tbk-bg-basic-white ${
            showAvailabilityCalendar ? "tbk-pb-[28px]" : "tbk-pb-2"
          } md:tbk-bg-grey-light md:tbk-pb-4 md:tbk-pb-4 lg:tbk-px-4`}
        >
          <div
            className={`search-filters tbk-flex tbk-flex-col tbk-justify-center tbk-bg-basic-white ${
              showAvailabilityCalendar ? "tbk-mb-2" : ""
            } tbk-pt-2 md:tbk-mb-2 md:tbk-bg-grey-light`}
          >
            <button
              className={`tbk-mx-2 tbk-mb-2 tbk-flex tbk-grow tbk-items-center tbk-rounded-lg tbk-border tbk-border-solid tbk-border-blue-grey-light tbk-bg-basic-white tbk-py-0.5 tbk-px-2 tbk-text-left md:tbk-hidden`}
              onClick={handleDisciplineFilterClick}
            >
              <div className={`tbk-mr-2 tbk-shrink-0 tbk-text-coral`}>
                <WorkoutIcon
                  label={selectedProduct ? selectedProduct.name : "Running"}
                  size={32}
                />
              </div>
              <div
                className={`tbk-text-h3-subtitle tbk-grow tbk-text-blue-dark`}
              >
                {selectedProduct ? selectedProduct.name : "Choose discipline"}
              </div>
              <div className={`tbk-shrink-0 tbk-text-blue-dark`}>
                <CaretDownIcon />
              </div>
            </button>
            <div
            // className={
            //
            //   clientWidth() < theme.responsive.breakpoints.md
            //     ? "tbk-pointer-events-none tbk-invisible tbk-absolute"
            //     : ""
            // }
            >
              {(showAvailabilityCalendar ||
                clientWidth() >= theme.responsive.breakpoints.md) && (
                <AvailabilityCalendar
                  currentDate={
                    _bookingTime &&
                    getMomentWithTz(_bookingTime, region).toDate()
                  }
                  initialVisibleMonth={availabilityCalendarMin}
                  onDatesChange={handleDatesChange}
                  onTimeChange={handleTimeChange}
                  region={region}
                  startDate={_startDate}
                  endDate={_endDate}
                  timeFormat={"HH:mm"}
                  min={availabilityCalendarMin.toDate()}
                  max={availabilityCalendarMax.toDate()}
                  day={availabilityCalendarDay}
                />
              )}
            </div>
            {!showAvailabilityCalendar &&
              clientWidth() < theme.responsive.breakpoints.md && (
                <button
                  className={`tbk-mx-2 tbk-flex tbk-grow tbk-items-center tbk-rounded-lg tbk-border tbk-border-solid tbk-border-blue-grey-light tbk-bg-basic-white tbk-px-2 tbk-py-0.5 tbk-text-left`}
                  onClick={handleChooseTimeDateClick}
                >
                  <div className={`tbk-mr-2 tbk-shrink-0 tbk-text-green`}>
                    <CalendarIcon size={32} />
                  </div>
                  <div
                    className={`tbk-text-h3-subtitle tbk-grow tbk-text-blue-dark`}
                  >
                    {_bookingTime
                      ? isTimeChanged
                        ? getMomentWithTz(_bookingTime, region).format(
                            "MMM D, h:mma"
                          )
                        : getMomentWithTz(_bookingTime, region).format("MMM D")
                      : "Choose time & date"}
                  </div>
                  <div className={`tbk-shrink-0 tbk-text-blue-dark`}>
                    <CaretDownIcon />
                  </div>
                </button>
              )}
          </div>
        </div>
      </div>

      <div className="tbk-bg-grey-light">
        <div className="tbk-container tbk-mx-auto tbk-hidden tbk-justify-center tbk-px-4 md:tbk-block">
          <div className="tbk-inline-flex tbk-rounded-t-2xl tbk-bg-basic-white tbk-shadow-trube">
            <TabButton
              className="tbk-border-r tbk-border-solid tbk-border-r-blue-grey-light"
              categoryName="All"
              active={activeProductCategory === "All"}
              onClick={handleCategoryChange}
            />
            <TabButton
              className="tbk-border-r tbk-border-solid tbk-border-r-blue-grey-light"
              categoryName="Fitness"
              active={activeProductCategory === "Fitness"}
              onClick={handleCategoryChange}
            />
            <TabButton
              className=""
              categoryName="Spirit"
              active={activeProductCategory === "Spirit"}
              onClick={handleCategoryChange}
            />
          </div>
        </div>
      </div>

      <div className="tbk-relative tbk-grow tbk-bg-grey-light tbk-pb-2 tbk-pt-4 md:tbk-bg-basic-white md:tbk-pt-2 md:tbk-pb-3">
        <div
          className={`tbk-container tbk-relative tbk-mx-auto tbk-justify-center tbk-px-2 md:tbk-px-4${
            hasMorePartners ? "" : " md:tbk-pb-7"
          }`}
        >
          <div className="tbk-align-stretch tbk-mb-3 tbk-hidden tbk-flex-wrap tbk-justify-start md:tbk-mb-5 md:tbk-flex md:tbk-flex-row">
            <div className="tbk-flex-top tbk-justify-space-between tbk-max-w-full md:tbk-mb-1">
              <div className="tb-slider tb-slider-workouts tbk--mx-4 tbk-flex tbk-scroll-px-4 tbk-overflow-scroll tbk-px-4">
                {_products && _products.length ? (
                  (_products.length > MAX_PRODUCTS_TO_SHOW && !showAll
                    ? _products.slice(0, MAX_PRODUCTS_TO_SHOW)
                    : _products
                  ).map((product) => (
                    <PartnerProductBadge
                      key={product.name}
                      className="tbk-mr-1 tbk-mb-1 tbk-h-4 tbk-py-0.5 tbk-px-1"
                      iconClassName="tbk-mr-0.5"
                      element={"button"}
                      active={
                        selectedProduct && selectedProduct.name === product.name
                      }
                      product={product}
                      onClick={handleProductSelect}
                    />
                  ))
                ) : (
                  <>
                    <div className="tbk-mr-1 tbk-mb-1 tbk-box-content tbk-h-4 tbk-w-24 tbk-rounded-lg tbk-border tbk-border-solid tbk-border-blue-grey-light tbk-bg-blue-grey-light" />
                    <div className="tbk-mr-1 tbk-mb-1 tbk-box-content tbk-h-4 tbk-w-24 tbk-rounded-lg tbk-border tbk-border-solid tbk-border-blue-grey-light tbk-bg-blue-grey-light" />
                    <div className="tbk-mr-1 tbk-mb-1 tbk-box-content tbk-h-4 tbk-w-24 tbk-rounded-lg tbk-border tbk-border-solid tbk-border-blue-grey-light tbk-bg-blue-grey-light" />
                    <div className="tbk-mr-1 tbk-mb-1 tbk-box-content tbk-h-4 tbk-w-24 tbk-rounded-lg tbk-border tbk-border-solid tbk-border-blue-grey-light tbk-bg-blue-grey-light" />
                    <div className="tbk-mr-1 tbk-mb-1 tbk-box-content tbk-h-4 tbk-w-24 tbk-rounded-lg tbk-border tbk-border-solid tbk-border-blue-grey-light tbk-bg-blue-grey-light" />
                    <div className="tbk-mr-1 tbk-mb-1 tbk-box-content tbk-h-4 tbk-w-24 tbk-rounded-lg tbk-border tbk-border-solid tbk-border-blue-grey-light tbk-bg-blue-grey-light" />
                  </>
                )}

                {!showAll &&
                _products &&
                _products.length > MAX_PRODUCTS_TO_SHOW ? (
                  <button
                    className="tbk-mb-1 tbk-flex tbk-items-center tbk-whitespace-nowrap tbk-rounded-lg tbk-border tbk-border-solid tbk-border-blue-grey-light tbk-bg-blue-grey-light tbk-px-1 tbk-py-1 tbk-text-primary hover:tbk-bg-grey-main hover:tbk-text-basic-white"
                    onClick={handleShowAllClick}
                  >
                    <svg
                      width="20"
                      height="4"
                      viewBox="0 0 20 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="tbk-mr-0.5"
                    >
                      <circle cx="2" cy="2" r="2" fill="currentColor" />
                      <circle cx="10" cy="2" r="2" fill="currentColor" />
                      <circle cx="18" cy="2" r="2" fill="currentColor" />
                    </svg>

                    <span className="tbk-text-small-bold tbk-uppercase">
                      Show all
                    </span>
                  </button>
                ) : null}
              </div>
            </div>
          </div>

          <div className="tbk-relative tbk-mb-3 tbk-mt-0 tbk-flex tbk-flex-col-reverse tbk-flex-wrap tbk-items-center tbk-justify-center tbk-border-b tbk-border-solid tbk-border-b-blue-grey-light tbk-pb-1 md:tbk-mb-7 md:tbk-flex-row md:tbk-flex-row md:tbk-pb-[19px] lg:tbk-mt-2 ">
            <div className="tbk-text-main tbk-hidden tbk-self-start tbk-text-blue-dark md:tbk-absolute md:tbk-left-0 md:tbk-self-center">
              {availablePartners.length} coach
              {availablePartners.length > 1 || availablePartners.length === 0
                ? "es"
                : ""}{" "}
              found:
            </div>
            <button
              className={`tbk-mb-4 tbk-flex tbk-max-w-full tbk-items-center tbk-rounded-lg tbk-border tbk-border-solid tbk-border-grey-inactive ${
                selectedLocation ? "tbk-py-0.5" : "tbk-py-1"
              } tbk-px-1 tbk-shadow-trube md:tbk-mb-0 lg:tbk-max-w-xs`}
              onClick={handleLocationClick}
            >
              <MapMarkerIcon className="tbk-shrink-0" />
              <div className="tbk-text-small tbk-mr-1 tbk-whitespace-nowrap tbk-text-blue-dark">
                Your Location:
              </div>
              <div
                className={`location-link tbk-text-small tbk-block tbk-overflow-hidden tbk-text-ellipsis tbk-whitespace-nowrap ${
                  selectedLocation ? "tbk-text-grey" : "tbk-text-coral"
                }`}
              >
                {selectedLocation
                  ? selectedLocation.value && selectedLocation.value.place
                    ? `${selectedLocation.value.name} (${selectedLocation.label})`
                    : selectedLocation.label
                  : "Not Selected"}
              </div>
            </button>
            <div />
          </div>

          {
            /*selectedLocation &&*/
            availablePartnersLoaded /* Needed for proper coach browsing */ &&
            availablePartners &&
            availablePartners.length ? (
              <div className="md:tbk-mt-2_ md:tbk-mb-3_ tbk-justify-center md:tbk-grid md:tbk-grid-cols-available-partners md:tbk-gap-3 xl:tbk-grid-cols-2">
                {availablePartners.map((val) => (
                  <Link
                    to={{
                      pathname: `/partners/${val.id}`,
                      search: `${
                        _directBookingTime
                          ? `?start_time=${_directBookingTime}`
                          : ""
                      }${
                        _directBookingTime && window.location.search
                          ? `&${window.location.search.substring(1)}`
                          : ""
                      }`,
                      state: { prevPath: location.pathname },
                    }}
                    itemProp="url"
                  >
                    <TrainerCard
                      key={val.id}
                      className="tbk-mb-2 md:tbk-mb-0"
                      trainer={{
                        ...val,
                        partnerRatingRecap: {
                          average: 4.91,
                          numberOfRatings: 121,
                        },
                      }}
                      // onBookWithMe={goToTrainerProfile}
                      onTimeSlotsClick={handleTimeSlotsClick}
                      // onCardClick={goToTrainerProfile}
                    />
                  </Link>
                ))}
              </div>
            ) : availablePartnersLoaded ? (
              <div className="md:tbk-min-h-available-partners">
                <div className="tbk-flex tbk-justify-center md:tbk-my-7">
                  <div className="tbk-p-2 md:tbk-p-4">
                    <div className="tbk-text-center tbk-text-grey-inactive">
                      <Partners />
                      <h2
                        className={`tbk-text-h3-subtitle tbk-mt-3 tbk-mb-2 tbk-text-blue-dark${
                          isFetching
                            ? " after:tbk-animate-dots after:tbk-content-['.']"
                            : ""
                        }`}
                      >
                        {isFetching ? (
                          <FormattedMessage
                            id="preparingTrainers"
                            defaultMessage="Preparing Trainers"
                          />
                        ) : (
                          <FormattedMessage
                            id="strugglingToFind"
                            defaultMessage="Struggling to find a Trainer?"
                          />
                        )}
                      </h2>
                    </div>
                    <p className="tbk-text-main tbk-mb-4 tbk-text-center tbk-text-primary">
                      Contact the member support team
                      <br />
                      and we will find the best for you.
                    </p>
                    <p className="tbk-text-h3-subtitle tbk-mb-2 tbk-text-center tbk-text-primary">
                      <PhoneIcon className="tbk-mr-1 tbk-text-blue-grey" />
                      <PhoneNumberLink
                        className="tbk-text-primary"
                        region={
                          region /*process.env.REACT_APP_REGION*/ === "GB"
                            ? "GB"
                            : "US_redirect"
                        }
                        usFormat="national"
                      />
                    </p>
                    <p className="tbk-text-h3-subtitle tbk-text-center tbk-text-primary">
                      <EnvelopeIcon className="tbk-mr-1 tbk-text-blue-grey" />
                      <a
                        href="mailto:members@trubeapp.com"
                        className="tbk-text-primary"
                      >
                        members@trubeapp.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              !isAavailablePartnersFetching && (
                <div className="md:tbk-min-h-available-partners" />
              )
            )
          }

          {
            /*(availablePartnersLoaded && availablePartnersPageLoaded >= 0) || */ isAavailablePartnersFetching ? (
              <div className="md:tbk-min-h-available-partners">
                <div className="tbk-justify-center md:tbk-mt-3 md:tbk-mb-5 md:tbk-grid md:tbk-grid-cols-available-partners md:tbk-gap-3">
                  {placeholders.map((partner, index) => (
                    <TrainerCardPlaceholder
                      className="tbk-mb-2 md:tbk-mb-0"
                      key={index}
                    />
                  ))}
                </div>
              </div>
            ) : null
          }

          {hasMorePartners && !isAavailablePartnersFetching ? (
            <Button
              variant="secondary"
              className="md:tbk-mt-7_ tbk-mx-auto tbk-mt-7 tbk-mb-2 tbk-w-68 md:tbk-mb-7"
              onClick={() =>
                loadMoreAvailablePartners(availablePartnersPageLoaded + 1)
              }
            >
              Show more
            </Button>
          ) : null}
        </div>
      </div>
      {isBookingFlowPopupOpen && (
        <div
          id="our-trainers-page-overlay"
          className="tbk-fixed tbk-top-0 tbk-left-0 tbk-bottom-0 tbk-right-0 tbk-z-1 tbk-bg-transparent tbk-opacity-0 tbk-transition-opacity"
          onClick={closeBookingFlowPopup}
        />
      )}
      <Footer regionSelect={false} />
      <Modal
        isOpen={isLocationSelectorModalOpen}
        // onAfterOpen={afterOpenModal}
        closeTimeoutMS={300}
        onRequestClose={() => closeLocationSelectorModal(true)}
        style={locationSelectorModalCustomStyle}
        contentLabel="Location Selector Modal"
      >
        <button
          onClick={() => closeLocationSelectorModal(true)}
          className="tbk-absolute tbk-top-2 tbk-right-2 tbk-z-10 tbk-z-10 tbk-text-coral"
        >
          <CloseIcon />
        </button>
        <div className="modal-select-location tbk-flex tbk-max-h-full tbk-w-full tbk-grow tbk-flex-col">
          <LocationSelector
            gymsListRef={refCallback}
            bookedPartner={bookedPartner}
            region={region}
            gyms={bookedPartner ? partnerGyms : gyms}
            selected={selectedLocation}
            closeTimeoutMS={300}
            onSelect={handleLocationSelect}
            onCloseClick={() => closeLocationSelectorModal(true)}
            showSpecifyLocationInfo={showSpecifyLocationInfo}
            onBackToSearchClick={() => setShowSpecifyLocationInfo(false)}
            onApplyLocationClick={() => closeLocationSelectorModal()}
            onShowAllGymsClick={(val) => setShowAllGyms(val)}
          />
        </div>
      </Modal>
      <Modal
        appElement={/*__CLIENT__ && */ document.getElementById("#app")}
        isOpen={isInfoModalOpen}
        // onAfterOpen={afterOpenInfoModal}
        onRequestClose={closeInfoModal}
        style={
          clientWidth() < theme.responsive.breakpoints.sm
            ? modalStyles.infoMobile
            : Object.assign(
                {},
                { ...modalStyles.info },
                {
                  content: {
                    ...modalStyles.info.content,
                    width: "320px",
                  },
                }
              )
        }
        contentLabel="Info Modal"
      >
        <div className="modal-info tbk-flex tbk-flex-col tbk-items-center tbk-bg-basic-white tbk-p-3 tbk-text-center">
          {/*<button*/}
          {/*  onClick={closeInfoModal}*/}
          {/*  className="tbk-absolute tbk-top-2 tbk-right-2 tbk-z-10 tbk-hidden tbk-text-coral"*/}
          {/*>*/}
          {/*  <CloseIcon />*/}
          {/*</button>*/}

          <CheckOIcon size={48} className="tbk-text-green" />
          {infoModalTitle ? (
            <h3 className="tbk-text-title-bold tbk-my-1">{infoModalTitle}</h3>
          ) : null}
          <p className="tbk-text-main tbk-text-primary">{infoModalMsg}</p>
        </div>
      </Modal>
      <TruBeDrawer
        attachRef={panelRef}
        attachContainerRef={panelContainerRef}
        allowDragging={isBookingFlowPanelOpen ? true : allowDragging}
        isOpen={isDisciplinesDrawerOpen || isBookingFlowPanelOpen}
        containerStyle={{
          width: "100%",
          padding: isDisciplinesDrawerOpen ? "16px" : null,
          height: isBookingFlowPanelOpen ? "100%" : null,
          overflowY: "auto", //isBookingFlowPanelOpen ? "auto" : null,
        }}
        // height={isDisciplinesDrawerOpen ? 954 : undefined}
        height={isBookingFlowPanelOpen ? undefined : null}
        title={isBookingFlowPanelOpen ? BookingFlowPanelHeader : null}
        titleElementProps={
          isBookingFlowPanelOpen
            ? {
                isOnline,
                partnerSummary,
                partnerLoaded,
                onClose: isDisciplinesDrawerOpen
                  ? closeDisciplinesDrawer
                  : closeBookingFlowPanel,
              }
            : null
        }
        onFullyOpen={handleFullyOpen}
        onBottomReached={handleBottomReached}
        onClose={
          isDisciplinesDrawerOpen
            ? closeDisciplinesDrawer
            : closeBookingFlowPanel
        }
      >
        {isDisciplinesDrawerOpen ? (
          <>
            <div className="tbk-mb-5">
              <div className="tbk-text-title-bold tbk-text-blue-dark">
                Fitness
              </div>
              <hr className="tbk-mt-1" />
              {products &&
                getProductsForCategory(products, "Fitness").map((val) => (
                  <DisciplineItem
                    key={val.id}
                    className="tbk-mb-3 tbk-w-full tbk-text-left"
                    selected={
                      selectedProduct && selectedProduct.name === val.name
                    }
                    product={val}
                    onClick={handleProductSelect}
                  />
                ))}
            </div>
            <div>
              <div className="tbk-text-title-bold tbk-text-blue-dark">
                Spirit
              </div>
              <hr className="tbk-mt-1" />
              {products &&
                getProductsForCategory(products, "Spirit").map((val) => (
                  <DisciplineItem
                    className="tbk-mb-3 tbk-w-full tbk-text-left"
                    selected={
                      selectedProduct && selectedProduct.name === val.name
                    }
                    product={val}
                    onClick={handleProductSelect}
                  />
                ))}
            </div>
          </>
        ) : (
          isBookingFlowPanelOpen &&
          clientWidth() < theme.responsive.breakpoints.lg && (
            <BookingFlowPanel
              ref={bookingFlowPanelRef}
              className=""
              isFullyOpen={isBookingFlowPanelFullyOpen}
              region={
                user ? user.region : region /*process.env.REACT_APP_REGION*/
              }
              partnerId={bookedPartner && bookedPartner.id}
              partnerFirstName={bookedPartner && bookedPartner.firstName}
              sessionType={sessionType}
              bookingStep={bookingStep}
              // userId={user && user.id}
              min={availabilityCalendarMin.toDate()}
              max={availabilityCalendarMax.toDate()}
              bookingTime={_directBookingTime}
              workout={directWorkout}
              workoutValues={partnerSummary && partnerSummary.products}
              partnerSummary={partnerSummary}
              loadPartnerProfile={loadPartnerProfile}
              partnerLoaded={partnerLoaded}
              partnerAvailability={partnerAvailability}
              partnerAvailabilityLoaded={partnerAvailabilityLoaded}
              clearPartnerAvailabilityLoaded={clearPartnerAvailabilityLoaded}
              onDateTimeSelect={handleDirectBookingTimeChange}
              onWorkoutChange={handleDirectWorkoutChange}
              onClose={closeBookingFlowPanel}
              onCtaClick={handleBookingFlowPopupCtaClick}
              // onSubmit={handleBookWithMeRequest}
            />
          )
        )}
      </TruBeDrawer>

      <Transition
        nodeRef={bookingFlowPopupRef}
        in={
          clientWidth() >= theme.responsive.breakpoints.lg &&
          isBookingFlowPopupOpen /*&& bookedPartner*/
        }
        timeout={300}
        // addEndListener={this.handleTransitionEnd}
      >
        {(status, innerProps) => (
          <BookingFlowPopup
            //{...innerProps}
            ref={bookingFlowPopupRef}
            className={`tbk-transition-transform tbk-duration-300 ${
              status === ENTERING || status === EXITED
                ? "tbk-translate-x-full tbk-opacity-5"
                : status === EXITING
                ? "tbk-translate-x-full tbk-opacity-5"
                : "tbk-translate-x-0 tbk-opacity-100"
            } ${transitionClasses[status]}`}
            region={
              user ? user.region : region /*process.env.REACT_APP_REGION*/
            }
            partnerId={bookedPartner && bookedPartner.id}
            sessionType={sessionType}
            bookingStep={bookingStep}
            userId={user && user.id}
            constrainTimesWithinRange={true}
            min={availabilityCalendarMin.toDate()}
            max={availabilityCalendarMax.toDate()}
            bookingTime={_directBookingTime}
            workout={directWorkout}
            workoutValues={partnerSummary && partnerSummary.products}
            partnerSummary={partnerSummary}
            loadPartnerProfile={loadPartnerProfile}
            partnerLoaded={partnerLoaded}
            partnerAvailability={partnerAvailability}
            partnerAvailabilityLoaded={partnerAvailabilityLoaded}
            // clearPartnerSummaryReceive,
            clearPartnerAvailabilityLoaded={clearPartnerAvailabilityLoaded}
            onDateTimeSelect={handleDirectBookingTimeChange}
            onWorkoutChange={handleDirectWorkoutChange}
            onClose={closeBookingFlowPopup}
            onCtaClick={handleBookingFlowPopupCtaClick}
            // onSubmit={handleBookWithMeRequest}
          />
        )}
      </Transition>
      <SendReviewsModal onClose={() => {}} />
      <Modal
        appElement={document.getElementById("#app")}
        isOpen={isSignUpModalOpen}
        // onAfterOpen={this.afterOpenModal}
        onRequestClose={closeSignUpModal}
        style={
          clientWidth() < theme.responsive.breakpoints.sm
            ? Object.assign(
                {},
                { ...modalStyles.mobileDefault },
                {
                  content: {
                    ...modalStyles.mobileDefault.content,
                    bottom: "unset",
                    padding: "24px",
                  },
                  overlay: {
                    ...modalStyles.mobileDefault.overlay,
                    zIndex: 1060,
                  },
                }
              )
            : Object.assign(
                {},
                { ...modalStyles.default },
                { overlay: { ...modalStyles.default.overlay, zIndex: 1060 } }
              )
        }
        contentLabel="Sign Up Modal"
      >
        <button
          onClick={closeSignUpModal}
          className="tbk-absolute tbk-top-2 tbk-right-2 tbk-z-10 tbk-text-coral"
        >
          <CloseIcon />
        </button>
        <SignUpForm
          smallButtons
          //withReferral={true}
          returnTo={
            isBookingFlow
              ? makeContinueBookingFlowUrl(location, undefined, isBookingFlow)
              : `${location.pathname}${location.search}`
          }
          onNext={(authVendor, params, isLogin) => {
            const nextAuthState =
              authVendor === "apple" ||
              authVendor === "google" ||
              authVendor === "facebook"
                ? "setPhoneNumber"
                : authVendor === "email"
                ? "setEmail"
                : isLogin
                ? "codeVerificationLogin"
                : "codeVerification";

            // Do we need it here??
            setAuthState(nextAuthState);

            // Is this the proper place to set Sign Up referral?
            // ONONZJRG
            Cookies.set(
              "referral",
              process.env.REACT_APP_SIGN_UP_REFERRAL_CODE,
              { path: "/" }
            );

            var search = `?authState=${nextAuthState}`;

            if (authVendor) {
              search += `&authVendor=${authVendor}`;
            }

            if (userPhoneCode) {
              search += `&phoneCode=${userPhoneCode}`;
            }
            if (userPhoneNumber) {
              search += `&phoneNumber=${userPhoneNumber}`;
            }

            if (authVendor === "facebook") {
              search += `&accessToken=${params.token}`;
            }

            if (authVendor === "google") {
              search += `&identityToken=${params.token}`;
            }

            if (authVendor === "google" || authVendor === "facebook") {
              search += `&firstName=${params.firstName}`;
            }

            if (authVendor === "google" || authVendor === "facebook") {
              search += `&lastName=${params.lastName}`;
            }

            if (authVendor === "google" || authVendor === "facebook") {
              search += `&email=${params.email}`;
            }

            // Here we save `state.from` so after login it'll be redirected to the proper state with query string saved.
            // Is it needed thou? Unlike Coach Profile where it is needed.
            history.push({
              pathname: "/login",
              search,
              state: {
                from:
                  isBookingFlow !== null
                    ? makeContinueBookingFlowUrl(
                        location,
                        CONTINUE_BOOKING_FLOW_PARAM_NAME,
                        isBookingFlow
                      )
                    : "",
              },
            });
          }}
          onSignIn={() => {
            closeSignUpModal();
            // continueBookingFlow();
          }}
          // onSignup={}
          // onLogin={}
          // onPasswordReset={}
          // isUserAuthenticating={}
          // loginWithFacebook={}
          // loginWithApple={}
          // register={}
        />
      </Modal>
    </div>
  );
}

OurTrainersPage.propTypes = {
  prevState: PropTypes.arrayOf(PropTypes.string),
};

const getProductByName = (products, name) => {
  return products && products.find((prod) => prod.name === name);
};

const getProductsForCategory = (products, category) => {
  return category === "All"
    ? products
    : products.filter((prod) => prod.category === category);
};

const getProductCategory = (products, productName) => {
  if (!products) {
    return;
  }
  var matchedProduct = products.find((prod) => prod.name === productName);

  if (matchedProduct) {
    return matchedProduct.category;
  }
};

const isProductVisible = (products, productName) => {
  if (!products || !products.length) {
    return false;
  }

  return Boolean(
    products
      .slice(0, MAX_PRODUCTS_TO_SHOW)
      .find((prod) => prod.name === productName)
  );
};

const getProductWithCategory = (products, category) => {
  return products.find((prod) => prod.category.toLowerCase() === category);
};

const partnerAvailable = (partnerId, availablePartners) => {
  return !!(
    availablePartners &&
    availablePartners.length &&
    availablePartners.find((val) => val.id === partnerId)
  );
};

const makeContinueBookingFlowUrl = (
  location,
  paramName = CONTINUE_BOOKING_FLOW_PARAM_NAME,
  paramValue
) => {
  const query = new URLSearchParams(location.search);
  let queryStr = "";

  if (query.has(CONTINUE_BOOKING_FLOW_PARAM_NAME)) {
    console.warn("Query param duplication!", CONTINUE_BOOKING_FLOW_PARAM_NAME);
  } else {
    queryStr = `${paramName}=${paramValue}`;
  }

  return `${location.pathname}${
    location.search ? `${location.search}&${queryStr}` : `?${queryStr}`
  }`;
};

const getStartDateFromQuery = (search) => {
  var query = new URLSearchParams(search);

  return query.get("date");
};

const getDateTimeFromQuery = (search) => {
  var query = new URLSearchParams(search);

  return query.get("dateWithTime");
};

const withLocalStorageWrapper = (fn, key) => {
  return (val) => {
    window.sessionStorage.setItem(key, val);
    fn(val);
  };
};
