import React from "react";

export default function TruBeNewIcon({ className, size, style }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`tbk-inline-block tbk-align-text-bottom${
        className ? " " + className : ""
      }`}
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 16.9705 16.9705 21 12 21C7.0296 21 3 16.9705 3 12C3 7.02946 7.0296 3 12 3C16.9705 3 21 7.02946 21 12Z"
        fill="#FF7557"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8932 8.20176C13.8995 9.25836 13.0483 10.1203 11.9917 10.1267C10.9345 10.1333 10.0729 9.28161 10.0664 8.22501C10.0601 7.16811 10.9115 6.30621 11.9684 6.30006C13.025 6.29346 13.8866 7.14486 13.8932 8.20176Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7708 15.8508C16.7708 15.8508 10.3502 11.9904 7.61206 16.246C7.61206 16.246 11.4765 19.72 16.7708 15.8508Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9737 10.6826C12.9737 10.6826 12.1729 13.4031 10.4441 13.4031C8.91878 13.4031 7.45703 10.1234 7.04993 10.098C7.04993 10.098 7.94018 9.01726 9.09683 9.76726C11.2405 11.157 12.1217 11.0387 12.9737 10.6826Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3274 13.3201C15.322 13.3648 19.1279 9.7201 15.0677 8.26465C14.6546 11.0899 12.6467 12.8638 12.3274 13.3201Z"
        fill="white"
      />
    </svg>
  );
}
