import React from "react";

export default function Globe({ className, fill }) {
  return (
    <svg
      className={`${className ? className : ""} tb-icon tb-icon-globe`}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      role="presentation"
      aria-hidden="true"
    >
      <g clipPath="url(#clip0_9522_2320)">
        <path
          d="M12 0.75C5.79675 0.75 0.75 5.79675 0.75 12C0.75 18.2033 5.79675 23.25 12 23.25C18.2033 23.25 23.25 18.2033 23.25 12C23.25 5.79675 18.2033 0.75 12 0.75ZM20.9979 8.25H16.9693C16.6155 5.97094 15.9429 4.065 15.0512 2.74594C17.7386 3.63431 19.9132 5.65725 20.9979 8.25ZM15.75 12C15.75 12.7858 15.7101 13.5352 15.6418 14.25H8.35819C8.28994 13.5352 8.25 12.7858 8.25 12C8.25 11.2142 8.28994 10.4648 8.35819 9.75H15.6416C15.7101 10.4648 15.75 11.2142 15.75 12ZM12 21.75C10.6459 21.75 9.17644 19.4113 8.55581 15.75H15.4442C14.8236 19.4113 13.3541 21.75 12 21.75ZM8.55581 8.25C9.17644 4.58869 10.6459 2.25 12 2.25C13.3541 2.25 14.8236 4.58869 15.4442 8.25H8.55581ZM8.94881 2.74594C8.05706 4.06519 7.38431 5.97094 7.03069 8.25H3.00206C4.08675 5.65725 6.26138 3.63431 8.94881 2.74594ZM2.52244 9.75H6.85013C6.78675 10.4739 6.75 11.2236 6.75 12C6.75 12.7764 6.78675 13.5261 6.85031 14.25H2.52244C2.35069 13.5262 2.25 12.7755 2.25 12C2.25 11.2245 2.35069 10.4738 2.52244 9.75ZM3.00206 15.75H7.0305C7.38431 18.0291 8.05688 19.935 8.94863 21.2541C6.26119 20.3657 4.08675 18.3428 3.00206 15.75ZM15.0512 21.2541C15.9429 19.9348 16.6157 18.0291 16.9693 15.75H20.9977C19.9132 18.3428 17.7388 20.3657 15.0512 21.2541ZM21.4776 14.25H17.1497C17.2132 13.5261 17.25 12.7764 17.25 12C17.25 11.2236 17.2132 10.4739 17.1497 9.75H21.4774C21.6493 10.4738 21.7498 11.2245 21.7498 12C21.7498 12.7755 21.6493 13.5262 21.4776 14.25Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_9522_2320">
          <rect width="24" height="24" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
}
