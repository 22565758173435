import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppState } from "../state";

// Components
import { RegionSelectModal } from "../components/RegionSelectModal/RegionSelectModal";

// Hooks
import useUserLocation from "../hooks/useUserLocation/useUserLocation";

// Utils
import { selectRegionBasedOnUserLanguages } from "../utils/locale";

const RegionContext = createContext();

export function useRegion() {
  return useContext(RegionContext);
}

export const getRegion = () => {
  return window.location.host === "us.trubeapp.com" ||
    window.location.pathname.startsWith("/us")
    ? "US"
    : "GB";
};

const initialRegion = getRegion();

export function RegionProvider({ children }) {
  const { user, hasTriedToAutoLogin } = useAppState();
  const { userLocation } = useUserLocation(user); // Location auto-detect

  const [region, setRegion] = useState(initialRegion);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Determine the initial region based on user status and userRegion
  const userRegion = useMemo(() => {
    if (user && hasTriedToAutoLogin) {
      return user.region;
    }
    if (userLocation) {
      return userLocation;
    }
    return getRegion();
  }, [user, hasTriedToAutoLogin, userLocation]);

  // Update region only after hasTriedToAutoLogin is true
  useEffect(() => {
    setRegion(userRegion /*user.region*/);
    localStorage.setItem("userRegion", userRegion /*user.region*/);
  }, [userRegion]);
  //}, [/*userRegion, */ hasTriedToAutoLogin]);

  const value = {
    region,
    setRegion,
    openModal,
  };

  return (
    <RegionContext.Provider value={value}>
      {children}
      <RegionSelectModal
        isOpen={isModalOpen}
        region={region}
        onClose={closeModal}
        onSelectRegion={setRegion}
      />
    </RegionContext.Provider>
  );
}
